export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      bShowPassword: false,
      bShowConfirmPassword: false,
      loadingForm: false,
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.passwordRecoveryTexts[this.selectLanguage];
  },
  methods: {
    passwordRecovery() {
      this.loadingForm = true;
      const self = this;

      const payload = {
        sToken: this.$route.params.sToken,
        sNewPassword: this.password,
        sConfirmNewPassword: this.confirmPassword,
      };

      //post credentials and get access token from laravel backend
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/recovery_password/`,
        payload
      )
        .then((response) => {
          this.loadingForm = false;

          this.mixSuccess(response.data.message);
          setTimeout(function () {
            self.$router.push("/");
          }, 2300);
        })
        .catch((error) => {
          this.loadingForm = false;
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
  },
  computed: {
    validateForm() {
      return (
        this.password !== "" &&
        this.confirmPassword !== "" &&
        this.password !== null &&
        this.confirmPassword !== null
      );
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.passwordRecoveryTexts[this.selectLanguage];
      }
    },
  },
};